// var stickyHeader = false;
// var transformHomepageDonationAmountImages = true;
// var flyoutNav = true;
// var fullscreenNav = false;
// var homeQuickGivingPreFooter = false;
var countUpStats = true;
var countUpStatsDuration = 3000;
// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video
// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// var feedItemsCarouselBreakpoint = 576; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;

if (window.matchMedia("(min-width: 1025px)").matches) {
  $(".headerContent a.mainLogo").insertBefore(".headerContent ul.socialIcons");
};


// JS moved from platform custom code
// SimonM 03.11.17 - 08.11.17 Replace text for tree dedication & Memberships
$('.donate.dedicate-a-tree-new-baby .yourDonation legend').first().html('1. Your dedication');
$('.donate-dedicate-a-tree-in-memory-20-credit-card .yourDonation legend').first().html('1. Your dedication');
$('.donate-dedicate-a-tree-wedding-gift-20-credit-card .yourDonation legend').first().html('1. Your dedication');
$('.donate-dedicate-a-tree-gift-20-credit-card .yourDonation legend').first().html('1. Your dedication');
$('.donate-dedicate-a-tree-christmas-gift-20-credit-card .yourDonation legend').first().html('1. Your dedication');
$('.donate-dedicate-a-tree-with-posted-certificate-25 .yourDonation legend').first().html('1. Your dedication');
$('.donate-20-dedicate-a-tree-fathers-day-20 .yourDonation legend').first().html('1. Your dedication');

$('.donate.dedicate-a-tree-new-baby .formQuestion.whyDonate label').first().html('Where did you hear about us?');
$('.donate-dedicate-a-tree-in-memory-20-credit-card .formQuestion.whyDonate label').first().html(
    'Where did you hear about us?');
$('.donate-dedicate-a-tree-wedding-gift-20-credit-card .formQuestion.whyDonate label').first().html(
    'Where did you hear about us?');
$('.donate-dedicate-a-tree-gift-20-credit-card .formQuestion.whyDonate label').first().html(
    'Where did you hear about us?');
$('.donate-dedicate-a-tree-christmas-gift-20-credit-card .formQuestion.whyDonate label').first().html(
    'Where did you hear about us?');
$('.donate.individual-membership-monthly-payment .formQuestion.whyDonate label').first().html(
    'Where did you hear about us?');
$('.donate.individual-membership-yearly-payment .formQuestion.whyDonate label').first().html(
    'Where did you hear about us?');
$('.donate-dedicate-a-tree-with-posted-certificate-25 .formQuestion.whyDonate label').first().html(
    'Where did you hear about us?');
$('.donate-20-dedicate-a-tree-fathers-day-20 .formQuestion.whyDonate label').first().html(
    'Where did you hear about us?');

if ($('body').hasClass('individual-membership-monthly-payment')) {
    var select = jQuery("#donationPeriod");
    var options = select.children();
    for (var i = 0; i <= options.length; i++) {
        var currentOption = jQuery(options[i]);
        if (currentOption.val() == "Monthly") {} else {
            currentOption.remove();
        }
    }
};

if ($('body').hasClass('donate-family-membership-monthly-payment-7-direct-debit')) {
    var select = jQuery("#donationPeriod");
    var options = select.children();
    for (var i = 0; i <= options.length; i++) {
        var currentOption = jQuery(options[i]);
        if (currentOption.val() == "Monthly") {} else {
            currentOption.remove();
        }
    }
};

// SimonM 29.11.17 uncheck and hide "Display name…" on certain donation forms
$('.donate.individual-membership-monthly-payment .formQuestion.displayName input:checkbox').prop('checked', false);
$('.donate.individual-membership-monthly-payment .formQuestion.displayName').hide();

$('.donate.individual-membership-yearly-payment .formQuestion.displayName input:checkbox').prop('checked', false);
$('.donate.individual-membership-yearly-payment .formQuestion.displayName').hide();

$('.donate.family-membership-monthly-payment .formQuestion.displayName input:checkbox').prop('checked', false);
$('.donate.family-membership-monthly-payment .formQuestion.displayName').hide();

$('.donate.family-membership-yearly-payment .formQuestion.displayName input:checkbox').prop('checked', false);
$('.donate.family-membership-yearly-payment .formQuestion.displayName').hide();

if ($('body').hasClass('turtle-dove-2022')) {
    $('.appealActionsWrapper').insertBefore('.appealWidgets').css('max-width', '100%').find('.donateBtn').css('width', '100%');
}